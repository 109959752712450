import { Flex } from 'src/ui/styles/objects/Flex'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { Text } from 'src/ui/components'
import { Translator, useTrans } from 'src/ui/hooks/useTrans'
import { useState, FC, useRef } from 'react'
import styles from './MyBarceloLoginButton.module.scss'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Media } from 'src/ui/styles/objects/Media'
import { User, UserLevel, UserLevelV2 } from 'src/core/User/domain/User'
import { Avatar } from './Avatar'
import { AuthDropdown } from './AuthDropdown'
import { getUserLevelText } from 'src/ui/i18n/getUserLevelText'
import { LoginIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/LoginIcon'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'
import { getUserLevelTextV2 } from 'src/ui/i18n/getUserLevelTextV2'
import { MyBarceloBenefitsLogoVertical } from 'src/ui/views/_components/MyBarceloBenefitsLogo'

interface Props {
  onLogin: () => void
  onLogout: () => Promise<void>
  user: User | undefined
}

const getTextByUser = (
  user: User | undefined,
  trans: Translator,
): string | undefined => {
  if (isDefined(user)) {
    const hasNewMyBarceloLoyalties = isFeatureFlagEnabled(
      'FF_FEATURE_5847_MY_BARCELO_REVAMP',
    )

    if (hasNewMyBarceloLoyalties) {
      return getUserLevelTextV2(user.level as UserLevelV2, trans)
    }

    return getUserLevelText(user.level as UserLevel, trans)
  }

  return undefined
}

export const MyBarceloLoginButton: FC<Props> = ({
  onLogin,
  user,
  onLogout,
}) => {
  const { trans } = useTrans(['common'])

  const [showDropdown, setShowDropdown] = useState(false)
  const triggerDropdownRef = useRef<HTMLButtonElement>(null)

  const hasNewMyBarceloLoyalties = isFeatureFlagEnabled(
    'FF_FEATURE_5847_MY_BARCELO_REVAMP',
  )

  const handleOnMyBarceloClick = () => {
    setShowDropdown(prev => !prev)
  }

  const handleOnOutsideDropdownClick = () => {
    setShowDropdown(false)
  }

  const userLevelText = getTextByUser(user, trans)

  return (
    <div className={styles.container}>
      <Media mobile tablet>
        <button
          className={styles.button}
          onClick={handleOnMyBarceloClick}
          ref={triggerDropdownRef}
          data-testid="mobile-login-button"
          aria-label={trans('header_my-barcelo')}
        >
          {isDefined(user) ? (
            <Avatar user={user} showCloseIcon={showDropdown} />
          ) : (
            <Icon
              icon={LoginIcon}
              size="l"
              color="icon-light"
              testId="my-barcelo-icon"
            />
          )}
        </button>
      </Media>

      <Media laptop desktop>
        <button
          className={styles.button}
          onClick={handleOnMyBarceloClick}
          ref={triggerDropdownRef}
          aria-label={trans('header_my-barcelo')}
        >
          {isDefined(user) ? (
            <Flex alignItems="center" gap="xxs">
              <Avatar user={user} showCloseIcon={showDropdown} />
              <Text fontStyle="m-300" color="light">
                {hasNewMyBarceloLoyalties
                  ? `${user.givenName} ${user.familyName}`
                  : `${trans('header_my-barcelo')} ${userLevelText}`}
              </Text>
            </Flex>
          ) : (
            <Flex alignItems="center" gap="xs">
              <Icon icon={LoginIcon} size="l" color="icon-light" />
              {hasNewMyBarceloLoyalties ? (
                <MyBarceloBenefitsLogoVertical variant="light" />
              ) : (
                <Text fontStyle="m-300" color="light">
                  {trans('header_my-barcelo')} {userLevelText}
                </Text>
              )}
            </Flex>
          )}
        </button>
      </Media>

      <AuthDropdown
        show={showDropdown}
        onLogin={onLogin}
        onLogout={onLogout}
        user={user}
        triggerRef={triggerDropdownRef}
        onClickOutside={handleOnOutsideDropdownClick}
      />
    </div>
  )
}
