import { classNames } from 'src/ui/utils/classnames'
import styles from './AuthDropdown.module.scss'
import {
  isUndefined,
  isDefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Text, Dropdown } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { User, UserLevel } from 'src/core/User/domain/User'
import { FC, RefObject, useState } from 'react'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { location } from 'src/ui/navigation/location'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { useCountry } from 'src/ui/contexts/CountryContext'
import { analytics } from 'src/core/Shared/infrastructure/analytics'
import { MyBarceloLogo } from 'src/ui/views/_components/MyBarceloLogo'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'
import { MyBarceloBenefitsLogoHorizontal } from 'src/ui/views/_components/MyBarceloBenefitsLogo'

interface Props {
  show: boolean
  onClickOutside: () => void
  onLogin: () => void
  onLogout: () => Promise<void>
  triggerRef?: RefObject<HTMLButtonElement>
  user: User | undefined
}

export const AuthDropdown: FC<Props> = ({
  user,
  show,
  onClickOutside,
  onLogout,
  triggerRef,
  onLogin,
}) => {
  const { trans } = useTrans(['common'])
  const { queryUtils } = useApplicationRouter()
  const languageConfig = useLanguageConfig()
  const country = useCountry()
  const [isInteracting, setIsInteracting] = useState(false)
  const hasNewMyBarceloLoyalties = isFeatureFlagEnabled(
    'FF_FEATURE_5847_MY_BARCELO_REVAMP',
  )

  const store = queryUtils.getRawParam('store')

  const handleOnPersonalDataClick = () => {
    analytics.actions.header.clickPersonalData()
    location.toUserPersonalData()
  }

  const handleOnMyReservationsClick = () => {
    analytics.actions.header.clickMyReservations()
    location.toMyReservations()
  }

  const handleRegisterClick = () => {
    const getRegisterURL = (): string => {
      const registerLanguage = languageConfig.properties.salesforceLanguage

      const registerReturnURL = document.URL + '&logged=true'
      const encodedRegisterReturnURL = encodeURIComponent(registerReturnURL)

      return (
        process.env.NEXT_PUBLIC_REGISTER_BASE_URL +
        `?language=${registerLanguage}&country=${country.code.toLowerCase()}&shop=${store}&returl=${encodedRegisterReturnURL}`
      )
    }

    analytics.actions.shared.clickRegister()
    location.replace(getRegisterURL())
  }

  const handleOnLogin = () => {
    setIsInteracting(true)
    onLogin()
  }

  const handleOnLogout = async () => {
    setIsInteracting(true)
    await onLogout()
    setIsInteracting(false)
  }

  return (
    <Dropdown
      show={show}
      onClickOutside={onClickOutside}
      triggerRef={triggerRef}
      className={classNames(
        styles.dropdown,
        isUndefined(user) && styles.unauthenticatedDropdown,
      )}
    >
      {isDefined(user) ? (
        <>
          <div className={styles.authenticatedHeader}>
            <div>
              <Text fontStyle="m-700" color="dark" as="p">
                {trans('header_auth_greeting', { name: user.givenName })}
              </Text>
              {isDefined(user.discount) && (
                <Text fontStyle="s-300" color="mid" as="p">
                  {trans('header_auth_discount', { discount: user.discount })}
                </Text>
              )}
            </div>
            {hasNewMyBarceloLoyalties ? (
              <MyBarceloBenefitsLogoHorizontal size="small" />
            ) : (
              <MyBarceloLogo width={93} level={user.level as UserLevel} />
            )}
          </div>
          <div className={styles.buttonsWrapper}>
            <button
              className={styles.listButton}
              onClick={handleOnPersonalDataClick}
            >
              <Text fontStyle="m-300" color="dark">
                {trans('header_auth_personal-data')}
              </Text>
            </button>
            <button
              className={styles.listButton}
              onClick={handleOnMyReservationsClick}
            >
              <Text fontStyle="m-300" color="dark">
                {trans('header_auth_my-reservations')}
              </Text>
            </button>
            <button
              className={styles.listButton}
              disabled={isInteracting}
              onClick={handleOnLogout}
            >
              <Text fontStyle="m-300" color="dark">
                {trans('header_auth_logout')}
              </Text>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className={styles.unauthenticatedHeader}>
            {hasNewMyBarceloLoyalties ? (
              <MyBarceloBenefitsLogoHorizontal size="small" />
            ) : (
              <MyBarceloLogo width={93} />
            )}
          </div>
          <button
            className={styles.listButton}
            disabled={isInteracting}
            onClick={handleOnLogin}
            data-testid="dropdown-login-button"
          >
            <Text fontStyle="m-700" color="dark">
              {trans('header_auth_login')}
            </Text>
          </button>
          <button
            className={classNames(styles.listButton, styles.register, 'mb-s')}
            onClick={handleRegisterClick}
          >
            <Text fontStyle="m-300" color="dark">
              {trans('header_auth_register')}
            </Text>
          </button>
        </>
      )}
    </Dropdown>
  )
}
