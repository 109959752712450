import { PaymentMethod } from 'src/core/Payment/domain/Payment.model'

export type FormPaymentMethod = Omit<
  Omit<PaymentMethod, 'name'>,
  'isValidForIframe'
>

const separator = '-'

export const serializeFormPaymentMethod = (
  formPaymentMethod: FormPaymentMethod,
): string => {
  const { groupId, id } = formPaymentMethod
  return `${groupId}${separator}${id}`
}

export const deserializeFormPaymentMethod = (
  serializedFormPaymentMethod: string,
): FormPaymentMethod => {
  const [groupId, id] = serializedFormPaymentMethod.split(separator)
  return {
    groupId,
    id,
  }
}
