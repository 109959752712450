import { PaymentMethod } from 'src/core/Payment/domain/Payment.model'
import { serializeFormPaymentMethod } from 'src/ui/utils/paymentMethodSerializer'

interface PaymentMethodConfig {
  id: string
  value: string
  label: string
  iconSrc: string
}

const iconSrcNameMap = {
  VISA: 'visa',
  VISA_DEBIT: 'visa-debit',
  VISA_ELECTRON: 'visa-electron',
  AMEX: 'american-express',
  MASTER_DEBIT: 'mastercard',
  MASTER: 'mastercard',
  MAESTRO: 'maestro',
  PAYPAL: 'paypal',
  JCB: 'jcb',
  DINER_CLUB: 'dinners-club',
  DISCOVER: 'discover',
  UNION_PAY: 'union-pay',
  APPLE_PAY: 'apple-pay',
  GOOGLE_PAY: 'google-pay',
}

const paymentNameMap = {
  VISA: 'Visa',
  VISA_DEBIT: 'Visa Debit',
  VISA_ELECTRON: 'Visa Electron',
  AMEX: 'American Express',
  MASTER_DEBIT: 'Master Debit',
  MASTER: 'Master Card',
  MAESTRO: 'Maestro',
  PAYPAL: 'PayPal',
  JCB: 'JCB',
  DINER_CLUB: 'Diners Club',
  DISCOVER: 'Discover',
  UNION_PAY: 'UnionPay',
  APPLE_PAY: 'Apple Pay',
  GOOGLE_PAY: 'Google Pay',
}

export const getPaymentMethodIconSrc = (methodId: string | undefined) => {
  return `/assets/images/payment-methods/${
    iconSrcNameMap[methodId as keyof typeof iconSrcNameMap] ?? 'card'
  }.png`
}

export const getMyBarceloLevelIconSrc = (level: string) => {
  return `/assets/images/myBarcelo/${level}.png`
}

export const getPaymentName = (methodId: string | undefined) => {
  return paymentNameMap[methodId as keyof typeof iconSrcNameMap] ?? ''
}

export const getPaymentMethodsConfig = (
  paymentMethods: PaymentMethod[],
): PaymentMethodConfig[] =>
  paymentMethods.map(method => ({
    id: method.id,
    value: serializeFormPaymentMethod({
      id: method.id,
      groupId: method.groupId,
    }),
    label: method.name,
    iconSrc: getPaymentMethodIconSrc(method.id),
  }))
