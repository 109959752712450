import { UserLevelV2 } from 'src/core/User/domain/User'
import { Translator } from 'src/ui/hooks/useTrans'

export const getUserLevelTextV2 = (level: UserLevelV2, trans: Translator) => {
  const userLevelTextMap: { [key in UserLevelV2]: string } = {
    [UserLevelV2.NotFound]: trans(
      'common:header_auth_my-barcelo-level_essential',
    ),
    [UserLevelV2.Essential]: trans(
      'common:header_auth_my-barcelo-level_essential',
    ),
    [UserLevelV2.Special]: trans('common:header_auth_my-barcelo-level_special'),
    [UserLevelV2.Unique]: trans('common:header_auth_my-barcelo-level_unique'),
  }

  return userLevelTextMap[level]
}
